import React from 'react'

import {
  Text,
  Flex,
  Box,
  Button
} from 'rebass'

import * as Yup from 'yup'

import { toast } from 'react-toastify'

import {
  Formik,
  Form,
  Field
} from 'formik'

import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'
import theme from '../components/theme'

const ContactPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text textAlign='center' py={2} fontWeight='bold' fontSize={5}>Contact Us</Text>
        </Box>
      </Flex>
    </Container>
    <Box bg='lightGray' py={5}>
      <Container>
        <Flex flexWrap='wrap' p={3} py={4}>
          <Box px={2} width={[1, 3/5]} mx='auto'>
            <Text fontSize={5} py={2} fontWeight='bold'>Let's talk!</Text>
            <Text color='gray'>Contact us for any further questions, possible projects and partnerships. Either fill out the form or email us directly at <a href='mailto:contact@reversity.io?subject=Reversity%20Inquiry&body='>contact@reversity.io</a>.</Text>

            <Formik
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Email is not valid.').required('Email is required'),
                fullName: Yup.string().required('Full name is required.'),
                message: Yup.string().required('Message is required.')
              })}
              initialValues={{fullName: '', email: '', message: ''}}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                let response = await fetch('https://09tgeiy3l8.execute-api.us-west-2.amazonaws.com/beta/inquiries', {
                  method: 'POST',
                  body: JSON.stringify({
                    "full_name": values.fullName,
                    "email":     values.email,
                    "message":   values.message
                  })
                })

                if (response.ok) {
                  toast.success("Your message was successfully sent to Reversity! Someone will be in contact with you shortly.", {
                    position: toast.POSITION.TOP_CENTER,
                    bodyClassName: 'toast'
                  })
                  resetForm()
                  setSubmitting(false)
                } else {
                  toast.error("Failed to submit information. Please try again.", {
                    position: toast.POSITION.TOP_CENTER
                  })
                }
              }}
              render={({ errors, touched, handleSubmit, isSubmitting }) => (
                <Form>
                  <Flex flexWrap='wrap'mx={-2}>
                    <Box px={2} width={[1, 1/2]}>
                      <Text fontSize={1} mt={3}><label style={{ color: theme.colors.gray }}>Full name</label></Text>
                      <Field name='fullName' placeholder='Jan Smith' style={{
                        color: theme.colors.gray,
                        backgroundPositionX: '-5000px !important',
                        boxShadow: (errors.fullName && touched.fullName) ? '0 0 0 1px rgba(255, 0, 0, 0.4)' : '0 0 0 1px #e4effa',
                        padding: '5px 20px 8px 13px',
                        transition: 'background-color .1s ease-in, color .1s ease-in',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        lineHeight: '26px',
                        fontSize: '14px',
                        width: '98%'
                      }}/>
                    </Box>
                    <Box px={2} width={[1, 1/2]}>
                      <Text fontSize={1} mt={3}><label style={{ color: theme.colors.gray }}>Email</label></Text>
                      <Field name='email' placeholder='jan@smith.com' style={{
                        color: theme.colors.gray,
                        backgroundPositionX: '-5000px !important',
                        boxShadow: (errors.email && touched.email) ? '0 0 0 1px rgba(255, 0, 0, 0.4)' : '0 0 0 1px #e4effa',
                        padding: '5px 20px 8px 13px',
                        transition: 'background-color .1s ease-in, color .1s ease-in',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        lineHeight: '26px',
                        fontSize: '14px',
                        width: '98%'
                      }}/>
                    </Box>
                    <Box px={2} width={1}>
                      <Text fontSize={1} mt={3}><label style={{ color: theme.colors.gray }}>Message</label></Text>
                      <Field component='textarea' name='message' rows='5' placeholder='Hello, Reversity!' style={{
                        color: theme.colors.gray,
                        backgroundPositionX: '-5000px !important',
                        boxShadow: (errors.message && touched.message) ? '0 0 0 1px rgba(255, 0, 0, 0.4)' : '0 0 0 1px #e4effa',
                        padding: '5px 20px 8px 13px',
                        transition: 'background-color .1s ease-in, color .1s ease-in',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        lineHeight: '26px',
                        fontSize: '14px',
                        width: '98%'
                      }}/>
                    </Box>
                  </Flex>
                  <Box py={2}>
                    <Button disabled={isSubmitting} type='submit' variant='primary' onClick={handleSubmit}>
                      { isSubmitting ? 'Sending...' : 'Send message' }
                    </Button>
                  </Box>
                </Form>
              )}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
    <BottomHero />
  </Layout>
)

export default ContactPage
